<template>
  <v-row>
    <!-- page header -->
    <v-col cols="12" v-if="inventoryProperty">
      <div class="d-flex justify-space-between px-4 py-2">
        <h1 class="display-1 font-weight-medium">
          Inventory {{ id }} / {{ inventoryProperty.source }}
        </h1>
        <div class="d-flex justify-space-between">
          <v-switch
            v-model="adminView"
            label="Admin View"
            class="ma-0 mr-10"
            :loading="loading"
            :disabled="loading"
            dense
          ></v-switch>
          <v-btn color="primary" depressed @click="$router.go(-1)">
            <v-icon left>mdi-arrow-left</v-icon>
            Back</v-btn
          >
        </div>
      </div>
    </v-col>

    <!-- description -->
    <v-col cols="12" v-if="inventoryProperty">
      <v-card outlined>
        <v-row>
          <v-col cols="12" md="6">
            <v-card-text v-if="isImageUrl">
              <v-img
                :src="inventoryProperty['image-src']"
                lazy-src="https://picsum.photos/id/11/10/6"
              />
            </v-card-text>
            <v-card-text v-else>
              <v-carousel
                cycle
                height="400"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item
                  v-for="(slide, i) in inventoryProperty['image-src']"
                  :key="i"
                >
                  <v-img
                    class="silder-inventory"
                    :src="slide.downloadUrl"
                    lazy-src="https://picsum.photos/id/11/10/6"
                  />
                </v-carousel-item>
              </v-carousel>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="6">
            <v-card-text>
              <p class="title">{{ inventoryProperty.resortName }}</p>
              <p class="text-capitalize">{{ inventoryProperty.destination }}</p>
              <p v-if="inventoryProperty.source == 'tripbeat'">
                {{ inventoryProperty.resortAddress }}
              </p>
              <p v-else v-html="inventoryProperty.resortAddress"></p>
              <p>
                Date Added to DB:
                {{ formatDate(inventoryProperty.dateSubmitted) }}
              </p>
              <p>{{ inventoryProperty.overview }}</p>
              <p v-if="inventoryProperty.source == 'tripbeat'">
                {{ inventoryProperty.unitInfo }}
              </p>
            </v-card-text>
          </v-col>
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel class="grey lighten-2">
                <v-expansion-panel-header>
                  Click for All Resort Information
                </v-expansion-panel-header>

                <!-- For All Sources -->
                <v-expansion-panel-content>
                  <!-- activities -->
                  <v-card-text v-if="inventoryProperty.activities">
                    <h3 class="mb-6">Activities</h3>
                    <div
                      v-html="inventoryProperty.activities"
                      class="gridSection"
                      v-if="typeof inventoryProperty.activities == 'string'"
                    ></div>
                    <div
                      id="activities"
                      v-else
                      v-for="(item, index) in inventoryProperty.activities"
                    >
                      <p>{{ item }}</p>
                    </div>
                    <v-divider class="mt-5" />
                  </v-card-text>
                  <!-- activities -->
                  <!-- airportInfo -->
                  <v-card-text v-if="inventoryProperty.airportInfo">
                    <h3 class="mb-6">Airport Information</h3>
                    <div
                      v-html="inventoryProperty.airportInfo"
                      id="airportInfo"
                      v-if="typeof inventoryProperty.airportInfo == 'string'"
                    ></div>
                    <div
                      id="airportInfo"
                      v-else
                      v-for="(item, index) in inventoryProperty.airportInfo"
                    >
                      <p>{{ item }}</p>
                    </div>
                    <v-divider class="mt-8" />
                  </v-card-text>
                  <!-- airportInfo -->
                  <!-- amenities -->
                  <v-card-text v-if="inventoryProperty.amenities">
                    <h3 class="mb-6">Amenities</h3>
                    <div
                      v-html="inventoryProperty.amenities"
                      class="gridSection"
                      v-if="typeof inventoryProperty.amenities == 'string'"
                    ></div>
                    <div
                      id="amenities"
                      v-else
                      v-for="(item, index) in inventoryProperty.amenities"
                    >
                      <p>{{ item }}</p>
                    </div>
                    <v-divider class="mt-8" />
                  </v-card-text>
                  <!-- amenities -->
                  <!-- checkInInfo -->
                  <v-card-text v-if="inventoryProperty.checkInInfo">
                    <h3 class="mb-6">Check In Information</h3>
                    <div
                      v-html="cleanUpCheckInInfo(inventoryProperty.checkInInfo)"
                      id="checkInInfo"
                      v-if="typeof inventoryProperty.checkInInfo == 'string'"
                    ></div>
                    <div
                      id="checkInInfo"
                      v-else
                      v-for="(item, index) in inventoryProperty.checkInInfo"
                    >
                      <p>{{ item }}</p>
                    </div>
                    <v-divider class="mt-8" />
                  </v-card-text>
                  <!-- checkInInfo -->
                  <!-- otherInfo -->
                  <v-card-text v-if="inventoryProperty.otherInfo">
                    <h3 class="mb-6">Other Information</h3>
                    <div
                      v-html="inventoryProperty.otherInfo"
                      id="otherInfo"
                      v-if="typeof inventoryProperty.otherInfo == 'string'"
                    ></div>
                    <div
                      id="otherInfo"
                      v-else
                      v-for="(item, index) in inventoryProperty.otherInfo"
                    >
                      <p>{{ item }}</p>
                    </div>
                    <v-divider class="mt-3" />
                  </v-card-text>
                  <!-- otherInfo -->
                  <!-- restaurants -->
                  <v-card-text v-if="inventoryProperty.restaurants">
                    <h3 class="mb-6">Restaurants &amp; Dining</h3>
                    <div
                      v-html="inventoryProperty.restaurants"
                      class="gridSection"
                      v-if="typeof inventoryProperty.restaurants == 'string'"
                    ></div>
                    <div
                      id="restaurants"
                      v-else
                      v-for="(item, index) in inventoryProperty.restaurants"
                    >
                      <p>{{ item }}</p>
                    </div>
                    <v-divider class="mt-3" />
                  </v-card-text>
                  <!-- restaurants -->
                  <!-- restrictions -->
                  <v-card-text v-if="inventoryProperty.restrictions">
                    <h3 class="mb-6">Policy Restrictions</h3>
                    <div
                      v-html="inventoryProperty.restrictions"
                      id="restrictionsInfo"
                      v-if="typeof inventoryProperty.restrictions == 'string'"
                    ></div>
                    <div
                      id="restrictionsInfo"
                      v-else
                      v-for="(item, index) in inventoryProperty.restrictions"
                    >
                      <p>{{ item }}</p>
                    </div>
                  </v-card-text>
                  <!-- restrictions -->
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- items/listings -->
    <v-col cols="12" v-if="items.length">
      <v-card outlined>
        <v-card-text class="text--primary">
          <h3 class="mb-4 font-weight-medium">Inventory Listings</h3>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            :items-per-page="-1"
            class="table-inventory"
          >
            <template v-slot:[`item.checkInDate`]="{ item }">
              {{ formatDate(item.checkInDate) }}
            </template>
            <template v-slot:[`item.checkOutDate`]="{ item }">
              {{ formatDate(item.checkOutDate) }}
            </template>
            <template v-slot:[`item.bedrooms`]="{ item }">
              <span class="text-capitalize">{{ item.bedroomsDisplay }}</span>
            </template>
            <template v-slot:[`item.kitchen`]="{ item }">
              <span class="text-capitalize">{{ item.kitchen }}</span>
            </template>
            <template v-slot:[`item.roomCost`]="{ item }">
              <span class="text-capitalize">$ {{ item.roomCost }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- property not found -->
    <v-col cols="12" v-else>
      <v-alert type="error">
        Inventory property <strong>{{ $route.params.id }}</strong> not found.
        Please alert IT of this error.
      </v-alert>
    </v-col>
    <!-- form -->
    <v-col cols="12" v-if="inventoryProperty">
      <v-card outlined>
        <v-card-text class="text--primary">
          <v-form ref="form" @submit.prevent="onSubmit">
            <!-- surcharges -->
            <v-row>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>Surcharges</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-switch
                      class="mx-8 mt-3"
                      label="Add Peak Season 1"
                      v-model="inventoryProperty.hasPeakSeasonSurcharge1"
                    ></v-switch>
                    <v-switch
                      class="mx-8 mt-3"
                      label="Add Peak Season 2"
                      v-model="inventoryProperty.hasPeakSeasonSurcharge2"
                    ></v-switch>
                    <v-switch
                      class="mx-8 mt-3"
                      label="Add High Occupancy"
                      v-model="inventoryProperty.hasHighOccupancySurcharge"
                    ></v-switch>
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="inventoryProperty.hasPeakSeasonSurcharge1"
              >
                <p>Select 2 dates to create a range</p>
                <v-date-picker
                  v-model="inventoryProperty.peakSeasonSurcharge1Range"
                  range
                  full-width
                  dense
                  class="border"
                  style="min-height: 450px"
                ></v-date-picker>
                <span class="d-block mt-4">
                  {{ inventoryProperty.peakSeasonSurcharge1Range }}</span
                >
              </v-col>

              <v-col
                cols="12"
                sm="6"
                v-if="inventoryProperty.hasPeakSeasonSurcharge2"
              >
                <p>Select 2 dates to create a range</p>
                <v-date-picker
                  v-model="inventoryProperty.peakSeasonSurcharge2Range"
                  range
                  class="border"
                  dense
                  full-width
                  style="min-height: 450px"
                ></v-date-picker>
                <span class="d-block mt-4">
                  {{ inventoryProperty.peakSeasonSurcharge2Range }}</span
                >
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                v-if="
                  inventoryProperty.hasPeakSeasonSurcharge1 ||
                  inventoryProperty.hasPeakSeasonSurcharge2
                "
              >
                <v-text-field
                  label="Peak Season Surcharge Amount"
                  :rules="[(v) => !!v || 'Field Required']"
                  dense
                  v-model="inventoryProperty.peakSeasonSurchargeAmount"
                  hint="Whole numbers only. No decimals, etc. (500)"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                v-if="inventoryProperty.hasHighOccupancySurcharge"
              >
                <v-text-field
                  label="High Occupancy Surcharge Amount"
                  :rules="[(v) => !!v || 'Field Required']"
                  dense
                  v-model="inventoryProperty.highOccupancySurchargeAmount"
                  hint="Whole numbers only. No decimals, etc. (500)"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  dense
                  v-model="inventoryProperty.taxesFeesPercentage"
                  label="Tax &amp; Fees Percentage *"
                  :rules="[(v) => v === 0 || !!v || 'Field Required']"
                  hint="Whole numbers only. No decimals, symbols etc. (500)"
                  persistent-hint
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  dense
                  v-model="inventoryProperty.gardenUnitUpgrade"
                  label="Garden Unit Upgrade"
                  hint="Whole numbers only. No decimals, etc. (500)"
                  persistent-hint
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  dense
                  v-model="inventoryProperty.costOfExtras"
                  label="Cost of Extras *"
                  :rules="[(v) => v === 0 || !!v || 'Field Required']"
                  hint="Whole numbers only. No decimals, etc. (500)"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  dense
                  v-model="inventoryProperty.minimumProfitAccepted"
                  label="Minimum Profit Accepted *"
                  :rules="[(v) => !!v || 'Field Required']"
                  hint="Whole numbers only. No decimals, etc. (500)"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  dense
                  v-model="inventoryProperty.commissionsPaidPercentage"
                  label="Commissions Paid Percentage *"
                  :rules="[(v) => !!v || 'Field Required']"
                  hint="Whole numbers only. No decimals, etc. (500)"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-3" />
              </v-col>
            </v-row>
            <!-- surcharges -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click.prevent="onSubmit" color="primary" depressed
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { portalsApp } from "@/firebase";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
export default {
  data: () => ({
    inventoryProperty: "",
    items: [],
    adminView: false,
    unitSizeHotels:["CHAH", 
"CPMD", 
"DSBA", 
"ECGV", 
"ERMB", 
"GHGL", 
"HBSV", 
"HCAH", 
"HCEI", 
"HCEM", 
"HCGF", 
"HCGP", 
"HCGT", 
"HCHE", 
"HCHE2",
"HCHH", 
"HCHM", 
"HCHP", 
"HCHT", 
"HCIO", 
"HCLE", 
"HCMG", 
"HCMT", 
"HCMT2",
"HCNH", 
"HCOS", 
"HCPL", 
"HCPM", 
"HCRH", 
"HCRH2",
"HCRN", 
"HCRP", 
"HCRP2",
"HCVM", 
"HGOH", 
"HHHN", 
"HMGM", 
"HMLV", 
"HMMH", 
"HPLV", 
"HSMD", 
"LHPL", 
"MBLV", 
"SPGH", 
"TAHS", 
"TJOE", 
"TWCC", 
"AODR", 
"OCAI",
"SPPD",
"BPGJ", 
"RDCB",]
  }),
  computed: {
    isImageUrl() {
      return typeof this.inventoryProperty["image-src"] == "string";
    },
    id() {
      return this.$route.params.id;
    },
    loading() {
      return this.$store.state.loading;
    },
    headers() {
      let gardenUnitUpgradePackageCodes = ["BDRC2"];
      if (this.adminView) {
        let items = [
          { text: "Destination", value: "destination" },
          { text: "Resort Name", value: "resortName" },
          { text: "Check In Date", value: "checkInDate" },
          { text: "Check Out Date", value: "checkOutDate" },
          { text: "# Nights", value: "numberOfNights" },
          { text: "Bedrooms", value: "bedrooms" },
          { text: "Max Occupancy", value: "maxOccupancy" },
          { text: "Kitchen", value: "kitchen" },
          { text: "Peak Season Ranges", value: "peakSeasonRanges" },
          { text: "Peak Season Surcharge", value: "peakSeasonSurchargeAmount" },
          {
            text: "High Occupancy Surcharge",
            value: "highOccupancySurchargeAmount",
          },
          // { text: "Total Surcharges", value: "totalSurcharges" },
          // { text: "Cost to Org", value: "costToOrg" },
          // { text: "Total Income", value: "totalIncome" },
          // { text: "Commissions Paid", value: "commissionsPaid" },
          // { text: "Unit Cost", value: "roomCost" },
          // { text: "Taxes and Fees", value: "taxesAndFees" },
          // { text: "Total Cost", value: "totalCost" },
          // { text: "Net Profit", value: "netProfit" },
          // { text: "Target Profit", value: "minimumProfitAccepted" },
          // { text: "Would Display", value: "wouldDisplay" },
          // { text: "Source", value: "source" },
        ];

        if (gardenUnitUpgradePackageCodes.find((pc) => pc === this.id)) {
          items.push(
            {
              text: "Garden Unit Upgrade",
              value: "gardenUnitUpgrade",
            },
            { text: "Total Surcharges", value: "totalSurcharges" },
            { text: "Cost to Org", value: "costToOrg" },
            { text: "Total Income", value: "totalIncome" },
            { text: "Commissions Paid", value: "commissionsPaid" },
            { text: "Unit Cost", value: "roomCost" },
            { text: "Taxes and Fees", value: "taxesAndFees" },
            { text: "Total Cost", value: "totalCost" },
            { text: "Net Profit", value: "netProfit" },
            { text: "Target Profit", value: "minimumProfitAccepted" },
            { text: "Would Display", value: "wouldDisplay" },
            { text: "Source", value: "source" }
          );
        } else {
          items.push(
            { text: "Total Surcharges", value: "totalSurcharges" },
            { text: "Cost to Org", value: "costToOrg" },
            { text: "Total Income", value: "totalIncome" },
            { text: "Commissions Paid", value: "commissionsPaid" },
            { text: "Unit Cost", value: "roomCost" },
            { text: "Taxes and Fees", value: "taxesAndFees" },
            { text: "Total Cost", value: "totalCost" },
            { text: "Net Profit", value: "netProfit" },
            { text: "Target Profit", value: "minimumProfitAccepted" },
            { text: "Would Display", value: "wouldDisplay" },
            { text: "Source", value: "source" }
          );
        }

        return items;
      } else {
        let items = [
          { text: "Destination", value: "destination" },
          { text: "Resort Name", value: "resortName" },
          { text: "Check In Date", value: "checkInDate" },
          { text: "Check Out Date", value: "checkOutDate" },
          { text: "# Nights", value: "numberOfNights" },
          { text: "Bedrooms", value: "bedrooms" },
          { text: "Maximum Occupancy", value: "maxOccupancy" },
          { text: "Kitchen", value: "kitchen" },
          { text: "Peak Season Surcharge", value: "peakSeasonSurchargeAmount" },
          {
            text: "High Occupancy Surcharge",
            value: "highOccupancySurchargeAmount",
          },
          // { text: "Total Surcharges", value: "totalSurcharges" },
        ];

        if (gardenUnitUpgradePackageCodes.find((pc) => pc === this.id)) {
          items.push(
            {
              text: "Garden Unit Upgrade",
              value: "gardenUnitUpgrade",
            },
            { text: "Total Surcharges", value: "totalSurcharges" }
          );
        } else {
          items.push({ text: "Total Surcharges", value: "totalSurcharges" });
        }
        return items;
      }
    },
  },
  watch: {
    id: {
      immediate: true,
      async handler(id) {
        if (id) {
          await this.$store.dispatch("setLoading", true);
          this.constructInventoryItem(id);
        }
      },
    },
  },
  methods: {
    cleanUpCheckInInfo(checkInInfo) {
      if (this.inventoryProperty.source == "hsi") {
        if (checkInInfo.includes("Check-In Days:")) {
          let newCheckInInfoOne = checkInInfo.split("<p>Check-In Days:")[0];
          let newCheckInInfoTwo = checkInInfo
            .split("<p>Check-In Days:")[1]
            .split("</p>");
          newCheckInInfoTwo.shift();
          return newCheckInInfoOne + newCheckInInfoTwo;
        }
      }
      if (this.inventoryProperty.source == "tripbeat") {
        if (checkInInfo.includes("Weeks Check In Days:")) {
          let newCheckInInfoOne = checkInInfo.split(
            "<p>Weeks Check In Days:"
          )[0];
          let newCheckInInfoTwo = checkInInfo
            .split("<p>Weeks Check In Days:")[1]
            .split("</p>");
          newCheckInInfoTwo.shift();
          return newCheckInInfoOne + newCheckInInfoTwo;
        }
      }
      return checkInInfo;
    },
    async onSubmit() {
      await this.$store.dispatch("setLoading", true);
      this.inventoryProperty.dateUpdated = new Date()
        .toISOString()
        .substring(0, 10);
      this.inventoryProperty.costOfExtras = parseInt(
        this.inventoryProperty.costOfExtras
      );
      this.inventoryProperty.highOccupancySurchargeAmount = parseInt(
        this.inventoryProperty.highOccupancySurchargeAmount
      );
      this.inventoryProperty.minimumProfitAccepted = parseInt(
        this.inventoryProperty.minimumProfitAccepted
      );
      this.inventoryProperty.commissionsPaidPercentage = parseInt(
        this.inventoryProperty.commissionsPaidPercentage
      );
      this.inventoryProperty.peakSeasonSurchargeAmount = parseInt(
        this.inventoryProperty.peakSeasonSurchargeAmount
      );
      this.inventoryProperty.taxesFeesPercentage = parseInt(
        this.inventoryProperty.taxesFeesPercentage
      );

      if (this.inventoryProperty.gardenUnitUpgrade) {
        this.inventoryProperty.gardenUnitUpgrade = parseInt(
          this.inventoryProperty.gardenUnitUpgrade
        );
      }

      let docRef = portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(this.id.toUpperCase());
      await docRef.update(this.inventoryProperty);
      await this.$store.dispatch("setLoading", false);
      this.handleSuccess("Inventory Property Updated Successfully");
      this.$router.push("/inventory");
    },
    calcTotalRate(nightlyRate, numberNights) {
      let number = nightlyRate * numberNights;
      return number.toFixed(2);
    },
    async constructInventoryItem(id) {
      let retailPackage = portalsApp
        .firestore()
        .collection("retailPackages")
        .where("inventoryProperties", "array-contains", id);

      retailPackage = await retailPackage.get();
      if (retailPackage.docs.length)
        retailPackage = retailPackage.docs[0].data();

      // get inventory property
      let propertyCheck = portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(id);

      propertyCheck = await propertyCheck.get();

      if (!propertyCheck.exists) {
        this.$store.state.loading = false;
        return;
      }

      let checkPeakSeasonDateValid = propertyCheck.data();
      if (
        checkPeakSeasonDateValid.hasPeakSeasonSurcharge1 &&
        checkPeakSeasonDateValid.hasOwnProperty("peakSeasonSurcharge1Range") &&
        checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1] <
          Moment().format("YYYY-MM-DD")
      ) {
        let range =
          Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1]).format(
            "YYYY"
          ) -
          Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[0]).format(
            "YYYY"
          );

        if (!range) {
          const isBefore =
            Moment(
              checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1]
            ).format("MM-DD") < Moment().format("MM-DD");

          if (isBefore) {
            checkPeakSeasonDateValid.peakSeasonSurcharge1Range = [
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[0])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
            ];
          } else {
            checkPeakSeasonDateValid.peakSeasonSurcharge1Range = [
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[0])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
            ];
          }
        } else {
          checkPeakSeasonDateValid.peakSeasonSurcharge1Range = [
            Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[0])
              .year(Moment().format("YYYY"))
              .format("YYYY-MM-DD"),
            Moment(checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1])
              .year(Moment().format("YYYY") * 1 + range)
              .format("YYYY-MM-DD"),
          ];
        }
      }
      if (
        checkPeakSeasonDateValid.hasPeakSeasonSurcharge2 &&
        checkPeakSeasonDateValid.hasOwnProperty("peakSeasonSurcharge2Range") &&
        checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1] <
          Moment().format("YYYY-MM-DD")
      ) {
        let range =
          Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1]).format(
            "YYYY"
          ) -
          Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[0]).format(
            "YYYY"
          );
        if (!range) {
          const isBefore =
            Moment(
              checkPeakSeasonDateValid.peakSeasonSurcharge1Range[1]
            ).format("MM-DD") < Moment().format("MM-DD");

          if (isBefore) {
            checkPeakSeasonDateValid.peakSeasonSurcharge2Range = [
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[0])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1])
                .year(Moment().format("YYYY") * 1 + 1)
                .format("YYYY-MM-DD"),
            ];
          } else {
            checkPeakSeasonDateValid.peakSeasonSurcharge2Range = [
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[0])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
              Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1])
                .year(Moment().format("YYYY"))
                .format("YYYY-MM-DD"),
            ];
          }
        } else {
          checkPeakSeasonDateValid.peakSeasonSurcharge2Range = [
            Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[0])
              .year(Moment().format("YYYY"))
              .format("YYYY-MM-DD"),
            Moment(checkPeakSeasonDateValid.peakSeasonSurcharge2Range[1])
              .year(Moment().format("YYYY") * 1 + range)
              .format("YYYY-MM-DD"),
          ];
        }
      }

      if (
        checkPeakSeasonDateValid.hasOwnProperty("peakSeasonSurcharge2Range") ||
        checkPeakSeasonDateValid.hasOwnProperty("peakSeasonSurcharge1Range")
      ) {
        let inventoryDocRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(id);

        await inventoryDocRef.update({
          peakSeasonSurcharge1Range:
            checkPeakSeasonDateValid.peakSeasonSurcharge1Range,
          peakSeasonSurcharge2Range:
            checkPeakSeasonDateValid.peakSeasonSurcharge2Range,
        });
      }

      // get inventory property
      let property = portalsApp
        .firestore()
        .collection("inventoryProperties")
        .doc(id);

      property = await property.get();

      // bind local propertyCheck
      this.inventoryProperty = property.data();
      property = property.data();
      // add costToOrg && bedrooms
      property.costToOrg = retailPackage.costToOrg;
      property.retailBedrooms = retailPackage.bedrooms;

      // reduce to values needed for table
      delete property.activities;
      delete property.airportInfo;
      delete property.amenities;
      delete property.checkInInfo;
      delete property.dateSubmitted;
      delete property.dateUpdated;
      delete property.destination;
      delete property.id;
      delete property["image-src"];
      delete property.otherInfo;
      delete property.overview;
      delete property.resortAddress;
      delete property.resortName;
      delete property.resortPropertyId;
      delete property.restaurants;
      delete property.restrictions;
      delete property.source;
      delete property.template;
      delete property.unitInfo;

      // get inventory items
      let listing = portalsApp
        .firestore()
        .collection("inventoryListings")
        .doc(id);

      listing = await listing.get();
      if (!listing.exists) {
        this.$store.state.loading = false;
        alert(
          `Inventory listings not found for ${this.$route.params.id}. Please alert IT of this error`
        );
        return;
      }

      let items = listing.data().items;

      // removing any items less than 14 days from today
      let twoWeeksFromToday = moment().add(14, "d");
      items = items.filter((i) => {
        let isBefore = moment(i.checkInDate).isBefore(twoWeeksFromToday);
        if (!isBefore) return i;
      });

      // add selected property values to listing item
      let combinedItems = [];
      items.forEach((i) => {
        delete i.dateUpdated;
        delete i.dateSubmitted;
        delete i.id;
        let item = {
          ...i,
          ...property,
        };
        combinedItems.push(item);
      });
      // format and push to UI
      this.formatAndPushInventoryItems(combinedItems);
    },
    async formatAndPushInventoryItems(combinedItems) {
      // format each item

      combinedItems.forEach((i) => {
        i.sortableCheckInDate = i.checkInDate.replace(/-/g, "").trim();

        // create peakSeasonRanges for UI table display
        let peakSeasonRanges = [];
        i.peakSeasonSurcharge1Range.forEach((i) => peakSeasonRanges.push(i));
        i.peakSeasonSurcharge2Range.forEach((i) => peakSeasonRanges.push(i));
        i.peakSeasonRanges = peakSeasonRanges;
        // create peakSeasonRanges for UI table display

        // create moment peakSeason ranges
        let momentPeakSeason1Range = moment.range(
          i.peakSeasonSurcharge1Range[0],
          i.peakSeasonSurcharge1Range[1]
        );
        let momentPeakSeason2Range = moment.range(
          i.peakSeasonSurcharge2Range[0],
          i.peakSeasonSurcharge2Range[1]
        );
        // create  moment peakSeason ranges

        // calc peakSeasonSurchargeAmount
        let originalPeakSeasonSurchargeAmount = i.peakSeasonSurchargeAmount;
        i.peakSeasonSurchargeAmount = 0;
        if (
          momentPeakSeason1Range.contains(moment(i.checkInDate)) ||
          momentPeakSeason2Range.contains(moment(i.checkInDate))
        ) {
          i.peakSeasonSurchargeAmount = originalPeakSeasonSurchargeAmount;
        }

        // highOccupancy surcharge calculation
        if (i.retailBedrooms >= i.bedrooms) {
          i.highOccupancySurchargeAmount = 0;
        }

        // gardenUnitUpgrade surcharge calculation
        if (i.gardenUnitUpgrade) {
          i.gardenUnitUpgrade = parseInt(i.gardenUnitUpgrade);
        } else {
          i.gardenUnitUpgrade = 0;
        }

        // hard coded display changes
        // resortPropertyId D709 => changes bedroomsDisplay 'studio' to 'hotel'
        // resortPropertyId D709 => sets maxOccupancy to 2
        if (i.resortPropertyId === "D709" && i.bedroomsDisplay === "studio") {
          i.bedroomsDisplay = "hotel";
          i.maxOccupancy = 2;
        }

        // totalSurcharges calculation

        i.totalSurcharges =
          parseInt(i.peakSeasonSurchargeAmount) +
          parseInt(i.highOccupancySurchargeAmount) +
          parseInt(i.gardenUnitUpgrade);

        i.totalIncome = i.costToOrg + i.totalSurcharges;
        i.commissionsPaid = (i.commissionsPaidPercentage / 100) * i.costToOrg;
        i.commissionsPaid = parseInt(i.commissionsPaid);
        i.taxesAndFees = (i.roomCost / 100) * i.taxesFeesPercentage;
        i.taxesAndFees = parseInt(i.taxesAndFees);
        i.totalCost = i.commissionsPaid + i.roomCost + i.taxesAndFees;
        i.netProfit = i.totalIncome - i.totalCost;
        let wouldDisplay = false;
        if (i.netProfit >= i.minimumProfitAccepted) wouldDisplay = true;
        i.wouldDisplay = wouldDisplay;
      });
      // sort by checkInDate && netProfit
      combinedItems = combinedItems.sort((a, b) => {
        if (a.sortableCheckInDate === b.sortableCheckInDate) {
          return b.netProfit - a.netProfit;
        }
        return a.sortableCheckInDate - b.sortableCheckInDate;
      });
      // add items to data
      combinedItems = combinedItems.map(el=> {
        if(!this.unitSizeHotels.includes(el.resortPropertyId)) return el;

        if(!el.bedrooms) {
          let element = el;
          element.bedroomsDisplay = "Hotel"
          return element;
        }
        return el;
      })
      this.items = combinedItems;
      await this.$store.dispatch("setLoading", false);
    },
  },
};
</script>

<style>
.gridSection h3,
#airportInfo h3,
#checkInInfo h3,
#restrictionsInfo h3,
#otherInfo h3 {
  display: none;
}
.gridSection .info-item {
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: auto auto auto auto;
}

#amenities_1 ul,
#amenities_2 ul {
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: auto auto auto;
  list-style-type: none;
}

#amenities_2 {
  margin-top: 30px;
}

#checkInInfo small {
  font-size: 100%;
}

#checkInInfo h5,
#amenities_1 h5,
#amenities_2 h5 {
  margin-bottom: 10px;
}

.table-inventory .v-data-table__wrapper {
  height: fit-content !important;
  max-height: 600px;
  overflow-y: auto;
}

.silder-inventory {
  object-fit: cover;
  height: 100%;
  width: auto;
}
/* tr:nth-child(even) {
  background: #fafafa;
} */

/* td {
  height: 36px !important;
  width: 1px;
  white-space: nowrap;
} */
</style>
